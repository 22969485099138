import React, {Component} from "react"
import {graphql} from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout";
import * as PropTypes from "prop-types";
import {Col, Descriptions, Row} from "antd";

import SEO from "../components/seo";
import Gallery from "../components/gallery";

import rehypeReact from "rehype-react";
import {ReadMoreComponent} from "./readMoreComponent";

const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {"read-more": ReadMoreComponent},
}).Compiler;

export default class ProjectTemplate extends Component {
    render() {
        let {data,} = this.props; // this prop will be injected by the GraphQL query below.
        const {markdownRemark, galleryImages} = data; // data.markdownRemark holds our post data
        const {frontmatter, htmlAst} = markdownRemark;

        let iprb = [2, 3];
        if (galleryImages.edges.length === 3) {
            iprb = [2, 2];
        }

        // const title_cols = 3;
        // const value_cols = 15;

        return (
            <Layout isProject={true}>
                <SEO title={frontmatter.title}/>

                <Row>
                    <Col md={18}>
                        <Img fluid={frontmatter.featuredImage.childImageSharp.fluid}/>

                        <br/><br/>
                    </Col>
                </Row>

                <Row>
                    <Col md={18}>
                        {/*<div className="project-desc-row">*/}
                        {/*    <div className="project-desc-1col">ОБЪЕКТ</div>*/}
                        {/*    <div className="project-desc-2col">{frontmatter.object}</div>*/}
                        {/*</div>*/}

                        {/*<div className="project-desc-row">*/}
                        {/*    <div className="project-desc-1col">МЕСТО</div>*/}
                        {/*    <div className="project-desc-2col">{frontmatter.place}</div>*/}
                        {/*</div>*/}


                        {/*<div className="project-desc-row">*/}
                        {/*    <div className="project-desc-1col">СТАДИЯ</div>*/}
                        {/*    <div className="project-desc-2col">{frontmatter.stage}</div>*/}
                        {/*</div>*/}

                        {/*<div className="project-desc-row">*/}
                        {/*    <div className="project-desc-1col">ДАТА</div>*/}
                        {/*    <div className="project-desc-2col">{frontmatter.dates}</div>*/}
                        {/*</div>*/}

                        <Descriptions column={1} size="small" colon={false}>
                            <Descriptions.Item label="ОБЪЕКТ">{frontmatter.object}</Descriptions.Item>
                            <Descriptions.Item label="МЕСТО">{frontmatter.place}</Descriptions.Item>
                            <Descriptions.Item label="СТАДИЯ">{frontmatter.stage}</Descriptions.Item>
                            <Descriptions.Item label="ДАТА">{frontmatter.dates}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>

                <br/>

                <Row>
                    <Col md={18}>
                        <div>{renderAst(htmlAst)}</div>
                    </Col>
                </Row>

                <br/>

                <Gallery
                    images={galleryImages.edges.map((image, idx) => ({
                        id: idx,
                        ...image.node.childImageSharp.fluid
                    }))}
                    itemsPerRowByBreakpoints={iprb}
                />
                {frontmatter.pauthor ? <div className="pauthor">
                    Фотографии: {frontmatter.pauthor_link ?
                        <a href={frontmatter.pauthor_link} target="_blank" rel="noopener noreferrer">
                            {frontmatter.pauthor}
                        </a> : frontmatter.pauthor
                    }
                </div> : ""}

                {data.subgalleries.edges.map((subg, index) => {
                    return <div key={index} style={{marginTop: "50px"}}>
                        <Row>
                            <Col md={18}>
                                <h3>{subg.node.childMarkdownRemark.frontmatter.title}</h3>
                                <div>{renderAst(subg.node.childMarkdownRemark.htmlAst)}</div>
                            </Col>
                        </Row>

                        <br/>

                        <Gallery
                            images={data.subgalleryImages.edges
                                .filter(({node}) => node.relativeDirectory.endsWith(subg.node.name))
                                .map((image, idx) => ({
                                    id: idx,
                                    ...image.node.childImageSharp.fluid
                                }))}
                            itemsPerRowByBreakpoints={iprb}
                        />
                        {subg.node.childMarkdownRemark.frontmatter.pauthor ? <div className="pauthor">
                            Фотографии: {subg.node.childMarkdownRemark.frontmatter.pauthor_link ?
                            <a href={subg.node.childMarkdownRemark.frontmatter.pauthor_link} target="_blank" rel="noopener noreferrer">
                                {subg.node.childMarkdownRemark.frontmatter.pauthor}
                            </a> : subg.node.childMarkdownRemark.frontmatter.pauthor
                        }
                        </div> : ""}
                    </div>
                })}
            </Layout>
        )
    }
}

ProjectTemplate.propTypes = {data: PropTypes.any};

export const pageQuery = graphql`
    query($path: String!, $galleryPath: String!, $relativeDirectory: String!, $subgalleriesRegex: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            htmlAst
            frontmatter {
                date(formatString: "D MMMM YYYY", locale: "RU")
                path
                title
                object
                place
                stage
                dates
                pauthor
                pauthor_link
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 1440, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }

        galleryImages: allFile(filter: { extension: {regex: "/(jpg)|(jpeg)|(png)/"},
            relativeDirectory: { eq: $galleryPath }},
            sort: { order: ASC, fields: [name] }) {
            edges {
                node {
                    name
                    childImageSharp {
                        fluid(maxWidth: 1440, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }

        subgalleries: allFile(filter: {relativeDirectory: {eq: $relativeDirectory}, name: {regex: "/gallery_.*/"}},
            sort: { order: ASC, fields: [name] })
        {
            edges {
                node {
                    relativeDirectory
                    name
                    childMarkdownRemark {
                        htmlAst
                        frontmatter {
                            title
                            pauthor
                            pauthor_link
                        }
                    }
                }
            }
        }

        subgalleryImages: allFile(filter: { extension: {regex: "/(jpg)|(jpeg)|(png)/"},
            relativeDirectory: { regex: $subgalleriesRegex }},
            sort: { order: ASC, fields: [name] }) {
            edges {
                node {
                    name
                    relativeDirectory
                    childImageSharp {
                        fluid(maxWidth: 1440, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
