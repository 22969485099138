import React, {Component} from "react";

export class ReadMoreComponent extends Component {
    state = {
        expanded: false
    };

    render() {
        let children = this.props.children;

        return this.state.expanded ?
            children :
            <div className="read-more">
                <a href={"/#"} onClick={e => this.expand(e)}>Читать дальше »</a>
            </div>;
    }

    expand = e => {
        e.preventDefault();

        this.setState({
            expanded: true
        });
    };
}